import { Component, OnInit } from "@angular/core";
import { MatDialog, PageEvent, Sort } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { PaginationModel } from "../../../models/pagination-model";
import * as _ from "lodash";
import { AddTeamComponent } from "../add-team/add-team.component";
import { TeamService } from "../../../Services/team/team.service";
import { DeleteTripsDialogComponent } from "../../vehicles/trips/driver-trips/delete-trips-dialog/delete-trips-dialog.component";

@Component({
  selector: "teams-list",
  templateUrl: "./teams-list.component.html",
  styleUrls: ["./teams-list.component.scss"],
})
export class TeamsListComponent implements OnInit {
  constructor(
    public service: TeamService,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {}

  isLoading = false;
  vehicle;
  paginationModel: PaginationModel<any> = <PaginationModel<any>>{
    items: [],
    properties: {
      search: "",
      sortProperty: "",
      sortBy: "",
      page: 1,
      pageSize: 10,
      length: 0,
    },
  };

  ngOnInit() {
    this.getTeams();
  }

  sortData(sort: Sort) {
    if (sort.direction === "asc") {
      this.paginationModel.properties.sortBy = "asc";
      this.paginationModel.properties.sortProperty = sort.active;
    } else if (sort.direction === "desc") {
      this.paginationModel.properties.sortBy = "desc";
      this.paginationModel.properties.sortProperty = sort.active;
    } else {
      this.paginationModel.properties.sortBy = "";
      this.paginationModel.properties.sortProperty = "";
    }
    this.getTeams();
  }

  onSearchChange = _.debounce(() => {
    this.getTeams();
  }, 600);

  paginatorChange(data: PageEvent) {
    this.paginationModel.properties.pageSize = data.pageSize;
    this.paginationModel.properties.page = data.pageIndex + 1;
    this.getTeams();
  }

  getTeams() {
    this.paginationModel.items = [];
    this.isLoading = true;
    this.service.pagination(this.paginationModel.properties).subscribe(
      (paginatedList: PaginationModel<any>) => {
        this.paginationModel = paginatedList;
        this.isLoading = false;
      },
      (err) => {}
    );
  }

  openConfirmationDialog(team): void {
    const dialogRef = this.dialog.open(DeleteTripsDialogComponent, {
      // width: '600px',
      // height: '400px',
      data: {
        body: this.translate.instant("Questions.AreYouSureDelete"),
        confirm: this.translate.instant("OneWord.Delete"),
        confirmColor: "danger",
        header: this.translate.instant("OneWord.Delete"),
        cancel: this.translate.instant("OneWord.Cancel"),
        recordName: team.name,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "submit") {
        this.isLoading = true;
        this.service.delete(team.id).subscribe(
          (res) => {
            this.getTeams();
          },
          (error1) => {
            this.isLoading = false;
          }
        );
      }
    });
  }

  edit(item: any) {
    const dialogRef = this.dialog.open(AddTeamComponent, {
      data: { editing: item },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "success") {
        this.getTeams();
      }
    });
  }

  addTeam(): void {
    const dialogRef = this.dialog.open(AddTeamComponent, {
      data: { editing: {} },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "success") {
        this.getTeams();
      }
    });
  }
}
