import { Injectable } from "@angular/core";
import { ApiUrls } from "../../configs/api-urls.config";
import { map } from "rxjs/operators/map";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as moment from "moment";
import { IListItemIcon } from "../../interfaces/list-item-icon.interface";
import { WorkScheduleProposalStatusEnum } from "../../pages/schedule/work-schedule/Enum-schedule/enum-status-change";
import { PaginationProperties } from "../../models/pagination-properties";
import { Observable } from "rxjs";
import { UpdateStatusModel } from "../../pages/schedule/work-schedule/Enum-schedule/update-status-model";

@Injectable({
  providedIn: "root",
})
export class WorkScheduleProposalService {
  constructor(private _http: HttpClient) {}

  FORM_SETTINGS: "form-settings";

  create(model, userId): any {
    model.firstUserId = userId;
    model.firstStartTime = this.getFirstStartTime(model);
    model.firstEndTime = this.getFirstEndTime(model);
    model.secondStartTime = this.getSecondStartTime(model);
    model.secondEndTime = this.getSecondEndTime(model);
    return this._http.post(ApiUrls.API_SCHEDULE_PROPOSAL_CREATE, model).pipe(
      map((res) => {
        return res;
      })
    );
  }
  CreateAssign(model, userId): any {
    model.firstUserId = userId;
    model.firstStartTime = this.getFirstStartTime(model);
    model.firstEndTime = this.getFirstEndTime(model);
    return this._http
      .post(ApiUrls.API_SCHEDULE_PROPOSAL_CREATE_ASSIGN, model)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getFirstStartTime(model) {
    const firstStartTime = new Date(model.startFromDate);
    firstStartTime.setDate(model.startFromDate.getDate());
    firstStartTime.setMonth(model.startFromDate.getMonth());
    firstStartTime.setFullYear(model.startFromDate.getFullYear());

    firstStartTime.setHours(model.firstStartTime.hour);
    firstStartTime.setMinutes(model.firstStartTime.minute);
    return firstStartTime;
  }

  getFirstEndTime(model) {
    const firstEndTime = new Date(model.startEndDate);
    firstEndTime.setDate(model.startEndDate.getDate());
    firstEndTime.setMonth(model.startEndDate.getMonth());
    firstEndTime.setFullYear(model.startEndDate.getFullYear());

    firstEndTime.setHours(model.firstEndTime.hour);
    firstEndTime.setMinutes(model.firstEndTime.minute);
    return firstEndTime;
  }

  getSecondStartTime(model) {
    const secondStartTime = new Date(model.endFromDate);
    secondStartTime.setDate(model.endFromDate.getDate());
    secondStartTime.setMonth(model.endFromDate.getMonth());
    secondStartTime.setFullYear(model.endFromDate.getFullYear());
    secondStartTime.setHours(model.secondStartTime.hour);
    secondStartTime.setMinutes(model.secondStartTime.minute);
    return secondStartTime;
  }

  getSecondEndTime(model) {
    const secondEndTime = new Date(model.endEndDate);
    secondEndTime.setDate(model.endEndDate.getDate());
    secondEndTime.setMonth(model.endEndDate.getMonth());
    secondEndTime.setFullYear(model.endEndDate.getFullYear());
    secondEndTime.setHours(model.secondEndTime.hour);
    secondEndTime.setMinutes(model.secondEndTime.minute);
    return secondEndTime;
  }

  public OcCardsStatus: IListItemIcon[] = [
    {
      value: WorkScheduleProposalStatusEnum.Pending,
      text: "Pending",
      color: "#F99500",
      icon: "fa-exclamation-triangle",
    },
    {
      value: WorkScheduleProposalStatusEnum.Rejected,
      text: "Rejected",
      color: "#FF0000",
      icon: "fa-exclamation-triangle",
    },
    {
      value: WorkScheduleProposalStatusEnum.Accepted,
      text: "Accepted",
      color: "#F99500",
      icon: "fa-exclamation-triangle",
    },
    {
      value: WorkScheduleProposalStatusEnum.NotApproved,
      text: "NotApproved",
      color: "#FF0000",
      icon: "fa-check-circle",
    },
    {
      value: WorkScheduleProposalStatusEnum.Approved,
      text: "Approved",
      color: "#44EB5E",
      icon: "fa-check-circle",
    },
  ];

  pagination(paginationProperties: PaginationProperties): any {
    return this._http
      .post(ApiUrls.API_SCHEDULE_PROPOSAL_PAGINATION, paginationProperties)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  GetAcceptedCount() {
    return this._http
      .get(ApiUrls.API_SCHEDULE_PROPOSAL_Accepted_COUNT)
      .pipe(
        map((res) => {
          setTimeout(() => {
            document.querySelectorAll(".menu-title").forEach((x: any) => {
              if (x.innerText.includes("Zgłoszone zamiany")) {
                let html = "";
                if (res)
                  html += `<span class="custom-badge badge-danger danger-problem mr-1">${res}</span>`;
                // if(res[0]) html = `<span class="custom-badge badge-warning rounded-circle mr-1">${res[0]}</span>`;
                // if(res[1]) html += `<span class="custom-badge badge-orange mr-1">${res[1]}</span>`
                // if(res[2]) html += `<span class="custom-badge badge-danger danger-problem mr-1">${res[2]}</span>`
                x.innerHTML = `Zgłoszone zamiany ${html}`;
              }
            });
          }, 3000);
          return res;
        })
      )
      .subscribe();
  }

  upDateStatus(status: UpdateStatusModel): Observable<any> {
    return this._http
      .post(ApiUrls.API_SCHEDULE_PROPOSAL_UPDATE_STATUS, status)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
