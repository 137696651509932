import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";

import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { AnalyticsService } from "../../../@core/utils/analytics.service";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { TranslateService } from "@ngx-translate/core";
import { filter, map, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { VehicleExpired } from "../../../models/vehicles/vehicle-expired.model";
import { Subject } from "rxjs";
import { VehicleExpiredService } from "../../../Services/vehicle/vehicle-expired.service";
import { AppSettings } from "../../../configs/app-settings.config";
import { AuthenticationService } from "../../../Services/authentication/authentication.service";
import { NotificationsService } from "../../../Services/dashboard/notifications.service";
import { PaginationModel } from "../../../models/pagination-model";
import { WorkScheduleProposalStatusEnum } from "../../../pages/schedule/work-schedule/Enum-schedule/enum-status-change";
import { getEnumAsArray } from "../../../@core/helpers/enums/enum";
import { UpdateStatusModel } from "../../../pages/schedule/work-schedule/Enum-schedule/update-status-model";
import { WorkScheduleProposalService } from "../../../Services/schedule/work-schedule-proposal.service";
import { MatDialog } from "@angular/material";
import { WorkScheduleProposalLogService } from "../../../Services/schedule/work-schedule-proposal-log.service";
import { ScheduleProposalLogModel } from "../../../models/work-schedule/ScheduleProposalLogModel";
import { SeenStatusEnum } from "../../../pages/schedule/work-schedule/Enum-schedule/enum-status-seen-change";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() position = "normal";
  private onDestroy$ = new Subject<boolean>();
  user: any;
  isAuthenticated = false;
  ExpiredVehicle: VehicleExpired[] = [];
  userMenu = [{ title: "Log out" }, { title: "Change password" }];
  isAdmin: boolean = false;
  notificationCount: number = 0;
  paginationModel: PaginationModel<ScheduleProposalLogModel> =
    new PaginationModel<ScheduleProposalLogModel>();
  isLoading: boolean;
  isDeleting: boolean;
  isConfirm: boolean;
  statusList: WorkScheduleProposalStatusEnum;
  proposalStatusEnum = getEnumAsArray(WorkScheduleProposalStatusEnum);
  searchText: string = "";
  selectedStatus: WorkScheduleProposalStatusEnum =
    WorkScheduleProposalStatusEnum.Pending;
  status: UpdateStatusModel = new UpdateStatusModel();
  notificationsList: any[] = [];
  workScheduleProposalStatusEnum = WorkScheduleProposalStatusEnum;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private analyticsService: AnalyticsService,
    private authService: NbAuthService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private router: Router,
    private _notificationService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private vehicleExpiredService: VehicleExpiredService,
    private workScheduleProposalService: WorkScheduleProposalService,
    private workScheduleProposalLogService: WorkScheduleProposalLogService,
    public dialog: MatDialog
  ) {
    this.userMenu = [
      { title: this.translate.instant("Menu.Account.ChangePassword") },
      { title: this.translate.instant("Menu.Account.Logout") },
    ];
    this.vehicleExpiredService.SubjectForExpiredVehicle.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((res: VehicleExpired[]) => {
      this.ExpiredVehicle = res;
    });
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      this.isAuthenticated = false;
      if (token.isValid()) {
        this.isAuthenticated = true;
        this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable
        this.getShiftChange();
      }
    });
    this.checkRole();
  }

  checkRole() {
    this.authenticationService.getRoles().subscribe((roles) => {
      this.isAdmin = roles.includes(AppSettings.ADMIN);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
  }

  ngOnInit() {
    this.getShiftChange();
    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "user-context-menu"),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        if (title === this.translate.instant("Menu.Account.Logout")) {
          this.router.navigateByUrl("/auth/logout");
        } else if (title === this.translate.instant("Menu.Account.Profile")) {
          this.router.navigateByUrl("/pages/profile");
        } else if (
          title === this.translate.instant("Menu.Account.ChangePassword")
        ) {
          this.router.navigateByUrl("/pages/profile/change-password");
        }
      });
    // Subscribe to the notification count from the service
    this._notificationService.notificationCount$.subscribe((count) => {
      this.notificationCount = count; // Update the badge count
      this.cdr.detectChanges();
    });
    this._notificationService.addNotifications().subscribe((notifications) => {
      this.notificationsList = notifications; // Update the notification list
      this.cdr.detectChanges();
      // this._notificationService.addNewNotification({ text: 'New comment added!' });
    });
  }
  resetNotificationCount() {
    // this.notificationCount = 0; // Reset the badge count
    if (this.paginationModel) {
      if (this.paginationModel.items) {
        var ids = this.paginationModel.items.map((x) => x.id);
        var _self = this;
        this.workScheduleProposalLogService
          .updateSeenStatus({ ids: ids })
          .subscribe((res) => {
            _self.paginationModel.items.map((x) => {
              x.seenStatus = SeenStatusEnum.Seen;
            });
          });
      }
    }
  }
  getShiftChange() {
    this.isLoading = true;
    this.paginationModel.items = [];

    var properties = { ...this.paginationModel.properties };
    properties.length = 2147483647;
    properties.sortBy = "desc";
    properties.sortProperty = "CreatedDateTime";

    this.workScheduleProposalLogService.pagination(properties).subscribe(
      (model: PaginationModel<ScheduleProposalLogModel>) => {
        if (model) {
          this.paginationModel = model;
        }
        this.isLoading = false;
        this.getNotificationCount();
        this.isDeleting = false;
        this.isConfirm = false;
      },
      (err) => {
        console.error("Error fetching logs:", err);
        this.isLoading = false;
      }
    );
  }
  getStatus(status: WorkScheduleProposalStatusEnum) {
    return this.workScheduleProposalService.OcCardsStatus.find(
      (x) => x.value === status
    );
  }
  confirmationMessage(proposal: any, confirmation: boolean) {
    this.isLoading = true;
    this.isConfirm = true;

    if (!this.status) {
      this.status = {
        id: null,
        status: null,
      };
    }

    this.status.id = proposal.id;
    this.status.status = confirmation
      ? WorkScheduleProposalStatusEnum.Accepted
      : WorkScheduleProposalStatusEnum.Rejected;

    this.workScheduleProposalLogService.upDateStatus(this.status).subscribe(
      (res) => {
        if (res) {
          this.paginationModel.properties.page = 1;
          this.getShiftChange();
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  deleteNotification(event: any, item: any) {
    this.isLoading = true;
    this.isDeleting = true;

    this.workScheduleProposalLogService
      .deleteStatus(item.id)
      .subscribe((res) => {
        console.log(res);
        this.paginationModel.properties.page = 1;
        this.getShiftChange();
        this.isLoading = false;
      });
  }
  setPaginationDefault(
    model: PaginationModel<ScheduleProposalLogModel>
  ): PaginationModel<ScheduleProposalLogModel> {
    if (!model.properties.extraField) {
      model.properties.extraField = null;
    }

    if (!model.properties.extraField3) {
      model.properties.extraField3 = null;
    }
    return model;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");

    return false;
  }

  // toggleSettings(): boolean {
  //   this.sidebarService.toggle(false, 'settings-sidebar');
  //
  //   return false;
  // }

  getNotificationCount() {
    if (this.paginationModel) {
      if (this.paginationModel.items) {
        return this.paginationModel.items.filter(
          (x) => x.seenStatus == SeenStatusEnum.Pending
        ).length;
      }
    }
    return 0;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent("startSearch");
  }
}
