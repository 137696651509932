import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { VehicleTripService } from "../../../Services/vehicle/vehicle-trip.service";
import { DynamicConfirmationDialogData } from "./DynamicConfirmationDialogData";

@Component({
  selector: "dynamic-confirmation-dialog",
  templateUrl: "./dynamic-confirmation-dialog.component.html",
  styleUrls: ["./dynamic-confirmation-dialog.component.scss"],
})
export class DynamicConfirmationDialogComponent implements OnInit {
  refuelData: FormGroup;
  isSubmitting: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DynamicConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DynamicConfirmationDialogData,
    private toaster: ToastrService,
    private vehicleTripService: VehicleTripService
  ) {}

  ngOnInit(): void {
    this.refuelData = new FormGroup({
      id: new FormControl(this.data.item.tripId),
      tankingKM: new FormControl(
        this.data.item.tankingKM || 0,
        Validators.required
      ),
      litersBought: new FormControl(this.data.item.litersBought || 0, [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.max(this.data.item.vehicle.tankCapacity),
      ]),
    });
    this.refuelData.get("litersBought").valueChanges.subscribe((value) => {
      if (value && /\D/.test(value)) {
        this.refuelData.get("litersBought").setValue(value.replace(/\D/g, ""));
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  blockInvalidInput(event: KeyboardEvent) {
    if (
      event.key === "e" ||
      event.key === "." ||
      event.key === "+" ||
      event.key === "-" ||
      event.key === "E" ||
      event.key === ","
    ) {
      event.preventDefault();
    }
  }

  onSubmitClick(): void {
    if (this.refuelData.valid) {
      this.isSubmitting = true;
      const kiloMeter = this.refuelData.get("tankingKM").value;
      const refuel = this.refuelData.get("litersBought").value;

      const refuelData = {
        litersBought: refuel,
        tankingKM: kiloMeter,
        id: this.data.item.tripId,
      };

      this.vehicleTripService.getTripRefill(refuelData, {}).subscribe(
        (response) => {
          // this.toaster.success(
          //   `Kilometer: ${kiloMeter} KM , Fuel: ${refuel} Liters`,
          //   "Refueling Data Submitted",
          //   { timeOut: 5000 }
          // );
          this.isSubmitting = false;
          this.dialogRef.close({
            status: "success",
            data: response,
          });
        },
        (error) => {
          // this.toaster.error("Error submitting refuel data. Please try again.", "Error", {
          //   timeOut: 5000,
          // });
          this.isSubmitting = false;
        }
      );
    } else {
      // this.toaster.error("Please fill out all fields correctly.", "Error", {
      //   timeOut: 5000,
      // });
    }
  }
}
