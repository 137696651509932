import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { StartBeforeEndTime } from '../../../../@core/helpers/validators/start-before-end-time.validator';
import { ToastrService } from 'ngx-toastr';
import { WorkScheduleService } from '../../../../Services/schedule/work-schedule.service';

@Component({
  selector: 'edit-end-shift',
  templateUrl: './edit-end-shift.component.html',
  styleUrls: ['./edit-end-shift.component.scss']
})
export class EditEndShiftComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private workScheduleService: WorkScheduleService,
    public dialogRef: MatDialogRef<EditEndShiftComponent>,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: { item: any }) { }


  ngOnInit() {
    if (this.data.item.id)
      this.registerForm = this.formBuilder.group({
        id: [this.data.item.id, [Validators.required]],
        to: [this.data.item.to, [Validators.required, Validators.minLength(1)]],
        endTime: [{
          hour: +this.datePipe.transform(new Date(this.data.item.to), 'HH'),
          minute: +this.datePipe.transform(this.data.item.to, 'mm'),
        }, [Validators.required]],
        numberKZW: [this.data.item.kzwNumber, [Validators.required, Validators.minLength(1)]],
        isPublished: [this.getInitialPublishedState()],
      }, {},
      );
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.workScheduleService.ChangeEndShift(this.registerForm.value, this.data.item.id).subscribe(res => {
      this.dialogRef.close({ status: 'success', data: res });
    }, (err) => {
      this.toastrService.error(err.error);
    });
  }
  private getInitialPublishedState(): boolean {
    if (this.data.item.isPublished === true) {
      return true;
    }
    return false;
  }
}
