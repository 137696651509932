import {Injectable} from '@angular/core';
import {ApiUrls} from '../../configs/api-urls.config';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {AppSettings} from '../../configs/app-settings.config';
import {PublicUserModel} from '../../models/admin/users/public-user-model';
import {PasswordResetModel} from '../../models/admin/users/password-reset-model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {


  constructor(private _http: HttpClient, private authService: NbAuthService,
              public snackBar: MatSnackBar) {

  }

  /**
   * change user password using his old one.
   * @param oldPassword old password.
   * @param newPassword new password.
   * @param confirmPassword confirm new password.
   * @return The user credentials.
   */
  changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
    return this._http.post<any>(ApiUrls.API_CHANGE_PASSWORD, {oldPassword, newPassword, confirmPassword})
      .pipe(map((response) => {
        // login successful if there's a jwt token in the response
      }));
  }

  isAuthorized() {
    return this._http.get<any>(ApiUrls.API_IS_AUTHORIZED)
      .pipe(map((response) => {
        // login successful if there's a jwt token in the response
      }));
  }

  public getCurrentUser() {
    return this._http.get(ApiUrls.API_GET_CURRENT_USER).pipe(map((res: PublicUserModel) => {
      return res;
    }));
  }

  getRoles(): Observable<string[]> {
    this.authService
    return this.authService.onTokenChange()
      .pipe(
        map((token: NbAuthJWTToken) => {
          return token.isValid() ? token.getPayload()[AppSettings.JWTRoles] : [AppSettings.GUEST];
        }),
      );
  }
  roleMatch(userRoles, allowedRole): boolean {
    return userRoles.includes(allowedRole) ? true : false;
  }


  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 5000,
    });
  }

}
