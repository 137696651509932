import {PaginationProperties} from './pagination-properties';

export class PaginationModel<T> {
  properties: PaginationProperties;
  items: Array<T>;
  constructor() {
    this.items = [];
    this.properties = {
      search: '',
      sortProperty: '',
      sortBy: '',
      page: 1,
      pageSize: 10,
      length: 0,
    };
  }
}
