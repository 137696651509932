export class ApiUrls {
  // Login
  public static readonly API_LOGIN = "/api/login/login";
  public static readonly API_LOGOUT = "/api/login/logout";
  public static readonly API_CHANGE_PASSWORD = "/api/password/change";
  public static readonly API_IS_AUTHORIZED = "/api/login/IsAuthorized";
  public static readonly API_LOGIN_GET_TEST = "/api/login";

  // Admin
  public static readonly API_GET_ALL_USERS_PAGINATION =
    "/api/admin/users/Pagination";
  public static readonly API_GET_ALL_USERS_IN_ROLE =
    "/api/admin/users/GetByRole";
  public static readonly API_GET_ALL_USERS_IN_TEAMS =
    "/api/admin/users/GetByTeam";
  public static readonly API_GET_ALL_USERS = "/api/admin/users/GetAll";
  public static readonly API_GET_ALL_SCHEDULE_USERS =
    "/api/admin/users/GetAllScheduleUsers";
  public static readonly API_GET_MY_SCHEDULE = "/api/admin/users/GetMySchedule";
  public static readonly API_GET_USER = "/api/admin/users/Get";
  public static readonly API_CREATE_USER = "/api/admin/users/Create";
  public static readonly API_DELETE_USER = "/api/admin/users/Delete";
  public static readonly API_UPDATE_USER = "/api/admin/users/Edit";
  public static readonly API_RESET_PASSWORD_USER =
    "/api/admin/users/ResetUserPassword";

  // User
  public static readonly API_GET_USERS = "/api/user/user/GetAllUsers";

  public static readonly API_GET_ALL_ROLES_Current_User =
    "/api/user/User/GetAllUserRoles";
  public static readonly API_GET_ALL_USERS_BY_TEAM_ROLE =
    "/api/user/User/GetAllUsersByTeamRole";
  public static readonly API_GET_ALL_ROLES = "/api/role/Role/GetAllRoles";
  public static readonly API_GETALL_LEADERS = "/api/user/user/GetAllLeaders";
  public static readonly API_GETALL_CONTRACT_AGREEMENT = "/api/user/user/GetAllUsersContractAgreement ";

  // Attachments
  public static readonly API_CREATE_ATTACHMENT =
    "/api/attachment/CreateAttachment";
  public static readonly API_GET_ATTACHMENT =
    "/api/attachment/DownloadAttachment";
  public static readonly API_DELETE_ATTACHMENT =
    "/api/attachment/DeleteAttachment";

  // Vehicles
  public static readonly API_CREATE_VEHICLE = "/api/vehicles/Create";
  public static readonly API_DELETE_VEHICLE = "/api/vehicles/Delete";
  public static readonly API_GET_VEHICLE = "/api/vehicles/Get";
  public static readonly API_GETALL_VEHICLE = "/api/vehicles/GetAll";
  public static readonly API_PAGINATION_VEHICLE = "/api/vehicles/Pagination";
  public static readonly API_CHECK_VEHICLE_EXPIRATION =
    "/api/vehicles/CheckExpiration";
  public static readonly API_TRIP_REFILL = "/api/Trip/Refill";

  // Equipments
  public static readonly API_CREATE_EQUIPMENTS = "/api/equipments/Create";
  public static readonly API_GET_EQUIPMENT = "/api/equipments/Get";
  public static readonly API_GET_ALL_VEHICLE_EQUIPMENTS =
    "/api/equipments/GetAllVehicleEquiipments";
  public static readonly API_DELETE_EQUIPMENTS = "/api/equipments/Delete";
  public static readonly API_GETALL_EQUIPMENTS = "/api/equipments/GetAll";
  public static readonly API_PAGINATION_EQUIPMENTS =
    "/api/equipments/Pagination";
  public static readonly API_GET_ALL_BY_VEHICLE =
    "/api/equipments/GetAllByVehicle";

  // Problems
  public static readonly API_PAGINATION_PROBLEMS = "/api/problem/Pagination";
  public static readonly API_UPDATE_PROBLEM = "/api/problem/Update";
  public static readonly API_GET_WAITING_PROBLEM =
    "/api/problem/GetWaitingCount";
  public static readonly API_GET_ALL_DRUGS = "/api/equipments/GetAllDrugs";
  public static readonly API_GET_ALL_OC_DRUGS = "/api/equipments/GetOCAllDrugs";
  "/api/problem/GetAttachments";
  public static readonly API_GET_ALL_Attachments =
    "/api/problem/GetAttachments";

  // ProblemRepairment
  public static readonly API_GET_PROBLEMREPAIRMENT =
    "/api/ProblemRepairment/Get";
  public static readonly API_ADD_PROBLEMREPAIRMENT =
    "/api/ProblemRepairment/Add";

  // OCCards
  public static readonly API_PAGINATION_OCCARDS = "/api/OcCard/Pagination";
  public static readonly API_UPDATE_OCCARDS = "/api/OcCard/Update";
  public static readonly API_ADD_UPDATE_OCCARD =
    "/api/OcCard/AddUpdateActivity";
  public static readonly API_GET_OCCARD = "/api/OcCard/Get";
  public static readonly API_CLOSE_OCCARD = "/api/OcCard/Close";
  public static readonly API_EXPORT_OCCARD = "/api/OcCard/Export";
  public static readonly API_TIE_OCCARDS = "/api/OcCard/Tie";
  public static readonly API_DELETE_OCCARDS = "/api/OcCard/Delete";
  public static readonly API_UPDATE_ONLY_ACCIDENT =
    "/api/OcCard/UpdateOnlyAccident";
  public static readonly API_CHECK_No_FINAL_QUANTITY_SHIFT =
    "/api/OcCard/CheckNoFinalQuantityShift";
  public static readonly API_CHECK_No_FINAL_QUANTITY_PREVIOUS =
    "/api/OcCard/CheckNoFinalQuantityPrevious";

  // Logs
  public static readonly API_PAGINATION_LOGS = "/api/OcCardLog/Pagination";

  // Vehicle insurance
  public static readonly API_CREATE_INSURANCE = "/api/insurance/create";
  public static readonly API_VEHICLE_INSURANCES =
    "/api/insurance/VehicleInsurances";
  public static readonly API_DELETE_VEHICLE_INSURANCES =
    "/api/insurance/Delete";

  // Vehicle  service service
  public static readonly API_CREATE_VEHICLE_SERVICE = "/api/service/Create";
  public static readonly API_DELETE_VEHICLE_SERVICE = "/api/service/Delete";
  public static readonly API_GET_VEHICLE_SERVICE = "/api/service/Pagination";

  // wheel service
  public static readonly API_CREATE_VEHICLE_WHEEL = "/api/wheel/Create";
  public static readonly API_DELETE_VEHICLE_WHEEL = "/api/wheel/Delete";
  public static readonly API_GET_VEHICLE_WHEEL = "/api/wheel/Pagination";

  //Prescription service
  public static readonly API_CREATE_PRESCRIPTION = "/api/prescription/Create";
  public static readonly API_PRESCRIPTION_PAGINATION =
    "/api/prescription/Pagination";
  public static readonly API_PRESCRIPTION_UPDATE_STATUS =
    "/api/prescription/Update-Status";
  public static readonly API_PRESCRIPTION_GET_PENDING_COUNT =
    "/api/prescription/GetPendingCount";

  // trip service
  public static readonly API_CREATE_VEHICLE_TRIP = "/api/trip/Create";
  public static readonly API_FINISH_VEHICLE_TRIP = "/api/trip/FinishTrip";
  public static readonly API_GET_LAST_TRIP = "/api/trip/VehicleLastTrip";
  public static readonly API_DELETE_VEHICLE_TRIP = "/api/trip/Delete";
  public static readonly API_PAGINATION_VEHICLE_TRIP = "/api/trip/Pagination";
  public static readonly API_PAGINATION_USER_TRIPS = "/api/trip/UserTrips";
  public static readonly API_GET_TRIP_USERS = "/api/trip/GetTripUsers";
  public static readonly API_GET_TRIP_TEAMS = "/api/trip/GetTripTeams";

  // Holiday service
  public static readonly API_CREATE_HOLIDAY = "/api/holiday/Create";
  public static readonly API_DELETE_HOLIDAY = "/api/holiday/Delete";
  public static readonly API_PAGINATION_HOLIDAY = "/api/holiday/Pagination";

  // teams service
  public static readonly API_CREATE_TEAM = "/api/teams/Create";
  public static readonly API_DELETE_TEAM = "/api/teams/Delete";
  public static readonly API_PAGINATION_TEAM = "/api/teams/Pagination";
  public static readonly API_GET_ALL_TEAMS = "/api/teams/GetAll";

  // schedule service
  public static readonly API_GET_WORK_SCHEDULE = "/api/schedule/Pagination";
  public static readonly API_PUBLISH_SCHEDULE = "api/schedule/PublishSchedule";
  public static readonly API_CLEAR_WORK_SCHEDULE =
    "/api/schedule/ClearSchedule";
  public static readonly API_CLEAR_DAYS_OFF = "/api/schedule/ClearDaysOff";
  public static readonly API_GET_MY_WORK_SCHEDULE =
    "/api/schedule/MySchedulePagination";
  public static readonly API_DELETE_WORK_SCHEDULE = "/api/schedule/Delete";
  public static readonly API_UPDATE_WORK_SCHEDULE_TEAM =
    "/api/schedule/UpdateWSTeam";
  public static readonly API_CREATE_WORK_SCHEDULE = "/api/schedule/Create";
  public static readonly API_CHECK_USER_WORK_SCHEDULE =
    "/api/schedule/CheckUserWorkSchedule";
  public static readonly API_USER_SCHEDULE_BY_DATE =
    "/api/schedule/GetWorkShiftsForUser";
  public static readonly API_SWITCH_USER_SHIFT =
    "/api/schedule/SwitchUserShift";
  public static readonly API_CHANGE_END_SHIFT = "/api/Schedule/ChangeEndShift";

  public static readonly API_Get_Work_Shifts_For_User_Start_End =
    "/api/schedule/GetWorkShiftsForUserStartEnd";

  //  Schedule Proposal
  public static readonly API_SCHEDULE_PROPOSAL_PAGINATION =
    "/api/ScheduleProposal/Pagination";
  public static readonly API_SCHEDULE_PROPOSAL_CREATE =
    "/api/ScheduleProposal/Create";
  public static readonly API_SCHEDULE_PROPOSAL_CREATE_ASSIGN =
    "/api/ScheduleProposal/CreateAssign";
  public static readonly API_SCHEDULE_PROPOSAL_DELETE =
    "/api/ScheduleProposal/Delete";

  public static readonly API_SCHEDULE_PROPOSAL_UPDATE_STATUS =
    "/api/ScheduleProposal/Update-Status";

  // public static readonly API_SCHEDULE_PROPOSAL_PENDING_COUNT =
  //   "/api/ScheduleProposal/GetPendingCount";

  public static readonly API_SCHEDULE_PROPOSAL_Accepted_COUNT =
    "/api/ScheduleProposal/GetAcceptedCount";

  //  Schedule Proposal Log
  public static readonly API_SCHEDULE_PROPOSAL_LOG_PAGINATION =
    "/api/ScheduleProposalLog/Pagination";
  public static readonly API_SCHEDULE_PROPOSAL_LOG_UPDATE_STATUS =
    "/api/ScheduleProposalLog/Update-Status";
  public static readonly API_SCHEDULE_PROPOSAL_LOG_UPDATE_SEEN_STATUS =
    "/api/ScheduleProposalLog/Update-Seen-Status";
  public static readonly API_SCHEDULE_PROPOSAL_LOG_DELETE_STATUS =
    "/api/ScheduleProposalLog/Delete";

  // reports
  public static readonly API_PRINT_COMPARABLE_REPORT =
    "/api/reports/ComparableReport";
  public static readonly API_PRINT_MULTIPLE_COMPARABLE_REPORTS =
    "/api/reports/MultipleComparableReports";
  public static readonly API_PRINT_OC_CARDS_REPORTS =
    "/api/reports/OcCardReports";
  public static readonly API_PRINT_PREVIOUS_OC_CARDS_REPORTS =
    "/api/reports/PreviousOcCardReports";
  public static readonly API_PRINT_TEAM_REPORT = "/api/reports/PrintTeamReport";
  public static readonly API_PRINT_USER_REPORT = "/api/reports/PrintUserReport";
  public static readonly API_PRINT_VEHICLES_REPORT =
    "/api/reports/PrintVehiclesReport";
  public static readonly API_PRESCRIPTION_REPORTS =
    "/api/reports/PrescriptionReports";

  // schedule capture
  public static readonly API_CREATE_CAPTURE_SCHEDULE =
    "/api/captureSchedule/Create";
  public static readonly API_GET_CAPTURED_SCHEDULES_BY_MONTH =
    "/api/captureSchedule/GetCapturesByMonth";
  public static readonly API_GET_CAPTURED_SCHEDULES_BY_PERIOD =
    "/api/captureSchedule/GetCapturesByPeriod";

  // user schedule
  public static readonly API_GET_USER_SCHEDULE =
    "/api/schedule/UserSchedulePagination";
  // public static readonly API_CLEAR_WORK_SCHEDULE = '/api/schedule/ClearSchedule';
  // public static readonly API_GET_MY_WORK_SCHEDULE = '/api/schedule/MySchedulePagination';
  // public static readonly API_DELETE_WORK_SCHEDULE = '/api/schedule/Delete';
  // public static readonly API_CREATE_WORK_SCHEDULE = '/api/schedule/Create';
  // public static readonly API_CREATE_PRINT_TEAM_REPORT = '/api/schedule/PrintTeamReport';

  // days off service
  public static readonly API_GET_DAYS_OFF = "/api/DaysOff/Pagination";
  public static readonly API_DELETE_DAY_OFF = "/api/DaysOff/Delete";
  public static readonly API_GET_USER_DAYS_OFF = "/api/DaysOff/GetUserDaysOff";
  public static readonly API_CREATE_DAY_OFF_LIST = "/api/DaysOff/CreateList";
  public static readonly API_CREATE_DAY_OFF = "/api/DaysOff/Create";
  public static readonly API_SWITCH_WS_TO_DAY_OFF = "/api/DaysOff/WSSwitch";
  public static readonly API_CREATE_REPLACEMENT_SHIFT =
    "/api/DaysOff/CreateReplacementShift";
  public static readonly API_GET_WORK_SCHEDULE_DURATIONS =
    "/api/daysOff/getWorkShiftDurations";

  // Dashboard
  public static readonly API_GET_CURRENT_USER = "/api/dashboard/GetCurrentUser";
  public static readonly API_GET_ALL_USER_CHECKS =
    "/api/dashboard/GetAllUsersChecks";
  public static readonly API_GET_EXPIRING_CAR_INSURANCES =
    "/api/dashboard/GetCarInsurances";
  public static readonly API_GET_EXPIRING_CAR_SERVICES =
    "/api/dashboard/GetCarChecks";
  public static readonly API_GET_NOTIFICATIONS =
    "/api/notification/GetNotifications";
  public static readonly API_NOTIFICATION_RESOLVE = "/api/notification/Resolve";
}
