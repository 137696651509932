export function getEnumKey(myEnum: any, value: any) {
  return myEnum[value];
}

export function getEnumKeys(enumKey: any) {
  return Object.keys(enumKey)
    .map((key) => enumKey[key])
    .filter((value) => typeof value === 'string') as string[];
}

export function getEnumAsArray(enumAsArray: any) {
  var enumArray = Object.keys(enumAsArray)
    .map((key) => enumAsArray[key])
    .filter((value) => typeof value === 'string')
    .map(function (type, index) {
      return { key: type, value: index };
    });
  return enumArray;
}
