import { Injectable } from "@angular/core";
import { ApiUrls } from "../../configs/api-urls.config";
import { map } from "rxjs/operators/map";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IListItem } from "../../interfaces/list-item.interface";
import { WorkScheduleTypeEnum } from "../../models/work-schedule/work-schedule-type.enum";
import * as moment from "moment";
import { IListItemIcon } from "../../interfaces/list-item-icon.interface";
import { WorkScheduleProposalStatusEnum } from "../../pages/schedule/work-schedule/Enum-schedule/enum-status-change";
import { PaginationProperties } from "../../models/pagination-properties";
import { Observable } from "rxjs";
import { UpdateStatusModel } from "../../pages/schedule/work-schedule/Enum-schedule/update-status-model";
import { UpdateSeenStatusModel } from "../../pages/schedule/work-schedule/Enum-schedule/update-seen-status-model";

@Injectable({
  providedIn: "root",
})
export class WorkScheduleProposalLogService {
  constructor(private _http: HttpClient) {}

  FORM_SETTINGS: "form-settings";

  pagination(paginationProperties: PaginationProperties): any {
    return this._http
      .post(ApiUrls.API_SCHEDULE_PROPOSAL_LOG_PAGINATION, paginationProperties)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  upDateStatus(status: UpdateStatusModel): Observable<any> {
    return this._http
      .post(ApiUrls.API_SCHEDULE_PROPOSAL_LOG_UPDATE_STATUS, status)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  deleteStatus(id): Observable<any> {
    return this._http
      .delete(ApiUrls.API_SCHEDULE_PROPOSAL_LOG_DELETE_STATUS + "?id=" + id)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateSeenStatus(
    updateSeenStatusModel: UpdateSeenStatusModel
  ): Observable<any> {
    return this._http
      .post(
        ApiUrls.API_SCHEDULE_PROPOSAL_LOG_UPDATE_SEEN_STATUS,
        updateSeenStatusModel
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
